import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';
import { Button } from '../components/button';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Link
      to="/"
      css={{
        paddingTop: `${rhythm(1)}`,
      }}
    >
      <Button>Wróć bezpiecznie</Button>
    </Link>
  </Layout>
);

export default NotFoundPage;
